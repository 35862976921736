
export const RQ_GET_DOCTOR_LIST_BY_NAME_API = {
  url: 'v2/doctor/search?',
  method: 'GET',
  auth: true,
  search:true,
};

export const RQ_HCP_REGISTER_ALL_FIELDS_API = {
  url: 'v2/registered-doctor/save',
  method: 'POST',
  auth: true,
};

export const RQ_GET_SEARCH_HCP_REGISTER_API = {
  url: 'v2/registered-doctor/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_HCP_PROFILE_API = {
  url: 'v2/registered-doctor/findById/<%= id %>',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_FMV_MASTER_API = {
  url: 'v2/fmv-master/all',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_REGISTERED_DOCTOR_BY_ID_API = {
  url: 'v2/registered-doctor/findById/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_APPROVE_HCP_API = {
  url: 'v2/registered-doctor/approve',
  method: 'PUT',
  auth: true,
};

export const RQ_RESUBMIT_HCP_API = {
  url: 'v2/registered-doctor/resubmit',
  method: 'PUT',
  auth: true,
};

export const RQ_REJECT_HCP_API = {
  url: 'v2/registered-doctor/reject',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_FMV_DETAIL_BY_ID_API = {
  url: 'v2/registered-doctor/fmv-details/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_BANK_DETAIL_BY_ID_API = {
  url: 'v2/bank-detail/by-owner_id/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_REGISTER_DOCTOR_LIST_BY_NAME_API = {
  url: 'v2/registered-doctor/search?',
  method: 'GET',
  auth: true,
  search:true,
};

export const RQ_GET_FMV_HISTORY_API = {
  url: 'v2/fmv-details/get-history/<%= ownerId %>',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_FMV_DETAILS_API = {
  url: 'v2/fmv-details/update-version/fmv-bank',
  method: 'PUT',
  auth: true,
};

export const RQ_UPDATE_BANK_DETAILS_API = {
  url: 'v2/bank-detail/version-update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_BANK_HISTORY_API = {
  url: 'v2/bank-detail/get-history/<%= ownerId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_DOCUMENT_HISTORY_API = {
  url: 'v2/documents/get-history/<%= ownerId %>',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_DOCUMENT_DETAILS_API = {
  url: 'v2/documents/version-update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_FMV_NEW_HISTORY_API = {
  url: 'v2/fmv-details/findById/<%= id %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_HCP_HISTORY_API = {
  url: 'v2/registered-doctor/getHistory/<%= ownerId %>',
  method: 'GET',
  auth: true,
};
