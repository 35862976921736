import React, {Suspense, lazy} from 'react';
import {ROUTE_CREATE_SCOPE_EVENT, ROUTE_KOL} from '../../RoutePathConstant';
import {Spin} from 'antd';
import {authLoader} from '../../loader';

const Kol = lazy(() =>
  import('../../../screens/KolEvents')
    .then(module => ({default: module.KolEvents})));

const ScopeEventCreation = lazy(() =>
  import('../../../screens/KolEvents')
    .then(module => ({default: module.CreateNewScopeEvent})));

const KolEventNavigation = [
  {
    path: ROUTE_KOL,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Kol />
      </Suspense>
    ),
  },
  {
    path: ROUTE_CREATE_SCOPE_EVENT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ScopeEventCreation />
      </Suspense>
    ),
  },
];
export default KolEventNavigation;
